@import "main.css";
@import "home.css";
@import "gestures.css";
@import "modal.css";

.App-header {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: white;
  position: relative;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

/*
 * LAYOUT
 *
 * main container set to fullscreen 100vh:
 * 1. .main-wrapper elements (webcam, gestures, ME display, etc)
 * 2. tab content expandable to remaining screen size
 *   flex: 0 0 auto (grow/shrink/basis initial size)
 *   flex: 1 1 auto (fill remainder)
 *
 */

#main {
  display: flex;
  flex-direction: column;
  height: 100vh;
}

.tab-content {
  flex: 1 1 auto;
  overflow-y: auto;
  padding: 0.5rem 1rem;
  margin: 0 -1rem;
}

/*
 *  layout grid for main-wrapper elements
 */

/* desktop view >= 768
 * 3 columns [~50%, spacing, ~50%], camera and full market when >= desktop
 * 2 rows: auto, and fill row
 *
 * mobile:
 * stacked, collapsed view < 768
 */

.output_canvas {
  background: url("https://www.washingtonpost.com/wp-apps/imrs.php?src=https://arc-anglerfish-washpost-prod-washpost.s3.amazonaws.com/public/4YWTZITUPII6VLM3EVHMTGMTXQ.jpg&w=916");
  background-size: cover;
  max-height: 30vh;
}

@media (min-width: 768px) {
  .d-grid.main-wrapper {
    grid-template-columns: calc(50% - 1.5rem * 0.5) 1.5rem calc(
        50% - 1.5rem * 0.5
      );
    grid-template-rows: auto 1fr;
  }

  .main-wrapper .camera {
    grid-column: 1/2;
    grid-row: 1/2;
  }

  .main-wrapper .gestures {
    grid-column: 1/2;
    grid-row: 2/3;
  }

  .main-wrapper .me {
    grid-column: 3/4;
    grid-row: 1/3;
  }

  .output_canvas {
    max-height: 40vh;
  }
}