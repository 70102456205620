/*
 * HOME PAGE (/)
 */

#nav-home-logo {
  font-family: "Overpass", sans-serif;
}

#home-background-image {
  position: fixed;
  z-index: -1;
  opacity: 0.2;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

#home-hero-text {
  font-family: "Overpass", sans-serif;
  font-size: 4rem;
  text-shadow: 14px 10px 14px rgba(0, 0, 0, 0.3);
  margin-top: 4rem;
}
@media (min-width: 768px) {
  #home-hero-text {
    font-size: 7rem;
  }
}

#home-hero-cta {
  font-size: 2rem;
  box-shadow: 14px 10px 14px rgba(0, 0, 0, 0.3);
}
@media (min-width: 768px) {
  #home-hero-cta {
    font-size: 3rem;
  }
}

/*
 * demo image
 */
#demo-image {
  width: 100%;
  @media (min-width: 768px) {
    width: auto;
  }
}

.demo-image-wrapper {
  display: flex;
  justify-content: center;
  overflow: hidden;
  max-width: 380px;

  box-shadow: 14px 10px 20px rgba(0, 0, 0, 0.25);

  @media (min-width: 768px) {
    max-width: 480px;
    max-height: 380px;
  }
}


/*
 * Badge box
 */

#home-badge {
  padding: 1rem;
  background: #ecae01;
  width: 16rem;
  margin-top: 2rem;
}
#home-badge input {
  font-size: 2rem;
}

#home-badge .no-border,
#home-badge .no-border:hover,
#home-badge .no-border:focus,
#home-badge .no-border:focus-visible,
#home-badge .no-border:active {
  border: 0;
  box-shadow: none;
  background: #ecae01;
  outline: none;
}

/*
 * CTA
 */

#home-instructions-cta {
  margin: 1em 0;
}

#webcam-disclaimer {
  position: absolute;
  bottom: 0;
  text-align: right;
  width: 100%;
  padding: 1rem;
  z-index:-9999;
}